/**
 * dev-ops-aware-devices-api-blue
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T19:45:32Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Describes a location in latitude and longitude
 */
export interface Location { 
    /**
     * The latitude of the location in decimal degrees.
     */
    latitude: number;
    /**
     * The longitude of the location in decimal degrees.
     */
    longitude: number;
}
