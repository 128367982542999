/**
 * dev-ops-aware-devices-api-blue
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T19:45:32Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * describes the version of a firmware image
 */
export interface FirmwareVersion { 
    /**
     * the build number of the firmware image
     */
    build: number;
    /**
     * the major version of the firmware image
     */
    major: number;
    /**
     * the minor version of the firmware image
     */
    minor: number;
}
