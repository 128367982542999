import { Injectable, OnDestroy } from '@angular/core'
import { DefaultService, UserOverview, UserUpdate } from 'src/app/ops-aware-users-api'
import { Observable, map, firstValueFrom, ReplaySubject, BehaviorSubject } from 'rxjs'


/**
 * Service used to provide and update information about the user that is
 * currently logged in.
 */
@Injectable({
    providedIn: 'root'
})
export class LoggedInUserService implements OnDestroy
{
    private userSettings = new  ReplaySubject<UserOverview>(1)

    get shouldShowTermsAndConditions(): Observable<boolean>
    {
        return this.overview().pipe(
            map((overview) =>
            {
                return !overview.acceptedTerms
            })
        )
    }

    /**
     * Updates the user agreement status.
     *
     * @param hasAgreed User's agreement status.
     */
    async updateUserAgreement(hasAgreed: boolean)
    {
        await this.setUserSettings({
            acceptedTerms: hasAgreed
        })

        await this.requestSettingsRefresh()
    }

    /**
     * Updates the user settings.
     *
     * @param settings User settings to be updated.
     */
    async setUserSettings(settings: UserUpdate)
    {
        await firstValueFrom(this.usersAPI.updateUser(settings))

        await this.requestSettingsRefresh()
    }

    /**
     * Retrieves the current user's overview.
     */
    overview(): Observable<UserOverview>
    {
        return this.userSettings.asObservable()
    }

    /**
     * Indicates whether the logged in user is an admin in any company
     * @returns Returns an observable indicating whether the user is a company admin
     */
    isCompanyAdmin(): Observable<boolean>
    {
        return this.overview().pipe(
            map((overview) => {

                for (let companyID in overview.associations)
                {
                    if (overview.associations[companyID].role == 'admin')
                    {
                        return true
                    }
                }

                return false
            })
        )
    }

    private async requestSettingsRefresh() {
        const user = await firstValueFrom(this.usersAPI.getAuthenticatedUser())

        this.userSettings.next(user)
    }

    constructor(
        private usersAPI: DefaultService
    )
    {

        this.requestSettingsRefresh()
    }

    ngOnDestroy()
    {
    }
}
