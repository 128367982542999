import { inject } from '@angular/core'
import { CanActivateFn, Router } from '@angular/router'
import { UserAuthService } from 'src/app/services/userAuth/user-auth.service'

export const isLoggedInGuard = async (route, state) =>
{
    const userAuth = inject(UserAuthService)
    const router = inject(Router)

    const session = await userAuth.getLoggedInUserSession()
    if (!session)
    {
        const urlTree = router.createUrlTree(['login'], {
            queryParams: {
                "state": state.url
            }
        })

        return urlTree
    }

    return true
}
