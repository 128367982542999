<div *ngIf="loading | async; else pageContent" class="loading">
    <mat-spinner></mat-spinner>
</div>
<ng-template #pageContent>
    <ng-container *ngIf="content !== undefined && content !== null; else notFound">
        <div *ngIf="mode =='editor'; else display" [class]="(fillScreen) ? '':'editor-container'">
            <div class="editor-header">
                <h2>Editing "{{path}}"</h2>
            </div>

            <md-editor name="Content"
                [upload]="doUpload"
                [preRender]="preRenderFunc"
                [postRender]="postRenderFunc"
                [(ngModel)]="content"
                [height]="editorHeight"
                [mode]="mode"
                [options]="options"
                (onEditorLoaded)="onEditorLoaded($event)"
                (onPreviewDomChanged)="onPreviewDomChanged($event)"
                required
                maxlength="500">
                <slot custom-btns></slot>
            </md-editor>
            <div class="actions">
                <button mat-raised-button (click)="discardPage()">Discard</button>
                <button mat-raised-button (click)="savePage()" [disabled]="(page) && content == page.document">Save</button>
            </div>
        </div>

        <ng-template #display>

            <div class="document-display" [style.max-width]="(fillScreen) ? undefined:'100ch'">
                <markdown [data]="content"></markdown>
                <mat-divider></mat-divider>
                <div class="admin-info" *ngIf="createdByUserDescription">
                    <div class="document-meta-data">
                        Last Edited on {{+page.createdOn | timeFormatting}} by {{createdByUserDescription}}
                    </div>
                    <div class="floating-tools">
                        <button mat-button (click)="editPage()"><mat-icon>edit</mat-icon>Edit</button>
                    </div>
                </div>
            </div>

        </ng-template>
    </ng-container>
    <ng-template #notFound>
        <div class="not-found">
            <h1>Not Found</h1>
            <p>The documentation page at the path "{{path}}" does not seem to exist.
                Please check that you have entered the correct address.
            </p>
            <p>
                If the documentation page should exist click the below button to create it.
            </p>
            <div class="not-found-actions">
                <button mat-raised-button (click)="createPage()">Create Page</button>
            </div>
        </div>
    </ng-template>
</ng-template>


