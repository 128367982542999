/**
 * dev-ops-aware-devices-api-blue
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T19:45:32Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MeasurementStats } from './measurementStats';


/**
 * Raw measurement made by a TempPatrol
 */
export interface RawTempPatrolMeasurement { 
    cpuTemperatureDegC: MeasurementStats;
    mcuVoltage: MeasurementStats;
    thermistor1DegC: MeasurementStats;
    thermistor2DegC: MeasurementStats;
}
