import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { LoggedInUserService } from 'src/app/services/logged-in-user/logged-in-user.service'
import { UserAuthService } from 'src/app/services/userAuth/user-auth.service'

@Component({
    selector: 'app-toolbar-user-info',
    templateUrl: './toolbar-user-info.component.html',
    styleUrls: ['./toolbar-user-info.component.css'],
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, MatIconModule]
})
export class ToolbarUserInfoComponent implements OnInit {

    constructor(private loggedInUser: LoggedInUserService,
                private userAuth: UserAuthService,
                private router: Router) { }

    email: string;

    ngOnInit() {

        this.loggedInUser.overview().subscribe((userInfo) => {

            if (userInfo === null || userInfo === undefined)
            {
                return;
            }

            this.email = userInfo.email
        });
    }

    goToProfile()
    {
        this.router.navigate(['/user'])
    }

    signout()
    {
        this.userAuth.signOut();
    }
}
