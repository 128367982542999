import { Component, HostListener, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SysAdminHeaderComponent } from '../../components/layout/sysadmin-header/header.component';
import { SideNavComponent } from 'src/app/components/layout/side-nav/side-nav.component'
import { MenuOptionGrouping } from 'src/app/menu-option'
import { HeaderComponent } from 'src/app/components/layout/header/header.component'
import { BaseMenuLayoutComponent } from '../base-menu-layout/base-menu-layout.component'
import { MainHeaderDirective } from 'src/app/directives/styling/main-header.directive'

@Component({
    selector: 'sysadmin-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.css'],
    standalone: true,
    imports: [SysAdminHeaderComponent, MatSidenavModule, SideNavComponent, RouterOutlet, HeaderComponent, BaseMenuLayoutComponent, MainHeaderDirective]
})
export class SysAdminLayoutComponent implements OnInit
{
    menuOptions: MenuOptionGrouping[] = [
        {
            name: "Navigation",
            options: [
                { name: "Status", routerSelector: "/sysadmin/status" },
                { name: "Users", routerSelector: "/sysadmin/users" },
                { name: "Companies", routerSelector: "/sysadmin/companies" },
                { name: "Formulas", routerSelector: "/sysadmin/formulas" },
                { name: "Firmware Update", routerSelector: "/sysadmin/firmware" },
                { name: "Documentation", routerSelector: "/sysadmin/documentation" },
                { name: "Logs", routerSelector: "/sysadmin/logs" },


            ]
        },
        {
            options: [
                { name: "OpsAware", routerSelector: "/"}
            ]
        }
    ]

    constructor() { }

    ngOnInit()
    {
    }

    ngAfterViewInit() { }

}
