/**
 * dev-ops-aware-devices-api-blue
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T19:45:32Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * A set of statistics for a measurement frame
 */
export interface MeasurementStats { 
    /**
     * The average measurement
     */
    average: number;
    /**
     * The maximum measurement
     */
    max: number;
    /**
     * The minimum measurement
     */
    min: number;
}
