import { Component, Input } from '@angular/core';
import { MenuOptionGrouping as MenuOptionGrouping } from 'src/app/menu-option';
import { MatDividerModule } from '@angular/material/divider';
import { RouterLink } from '@angular/router';
import { MatListModule } from '@angular/material/list';
import { NgFor, NgIf } from '@angular/common';
import { VersionService } from 'src/app/services/version/version.service'

@Component({
    selector: 'app-side-nav',
    templateUrl: './side-nav.component.html',
    styleUrls: ['./side-nav.component.css'],
    standalone: true,
    imports: [NgFor, MatListModule, NgIf, RouterLink, MatDividerModule]
})
export class SideNavComponent
{
    webAppVersion = "1.5.0";

    @Input()
    get items(): MenuOptionGrouping[]
    {
        return this.menuOptions
    }
    set items(newItems: MenuOptionGrouping[])
    {
        this.menuOptions = newItems
    }

    menuOptions: MenuOptionGrouping[];

    constructor(
        private version: VersionService
    ) {
        this.webAppVersion = version.webAppVersion
    }
}
