// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,

    userpoolID: "us-west-2_JmTaqTfbR",
    discoveryURL: 'https://cognito-idp.us-west-2.amazonaws.com/us-west-2_JmTaqTfbR',
    clientId: '15dma06bfboq62fkkmsiab788p',
    adminAPIURL: 'https://api.dev.ifd.dynamicsynergyiot.com/admin',
    devicesAPIURL: 'https://api.dev.ifd.dynamicsynergyiot.com/devices',
    userAPIURL: 'https://api.dev.ifd.dynamicsynergyiot.com/users',
    logsAPIURL: 'https://api.dev.ifd.dynamicsynergyiot.com/logs',
    helpAPIURL: 'https://api.dev.ifd.dynamicsynergyiot.com/help',
    userpoolURI: 'https://ops-aware-users-dev-login.auth.us-west-2.amazoncognito.com'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
