/**
 * dev-ops-aware-devices-api-blue
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T19:45:32Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Image change for a firmware update request
 */
export interface FirmwareUpdateChange { 
    /**
     * The ID of the image to change to, if the operation is set to \"UPDATE\". This field will be ignored if any other operation value is set.
     */
    imageID?: number;
    /**
     * The operation to apply
     */
    operation: FirmwareUpdateChange.OperationEnum;
}
export namespace FirmwareUpdateChange {
    export type OperationEnum = 'NONE' | 'CANCEL' | 'UPDATE';
    export const OperationEnum = {
        NONE: 'NONE' as OperationEnum,
        CANCEL: 'CANCEL' as OperationEnum,
        UPDATE: 'UPDATE' as OperationEnum
    };
}
