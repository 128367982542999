/**
 * dev-ops-aware-admin-api-blue
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T19:48:34Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * A collection of company details to add a company to the system
 */
export interface CompanyToAdd { 
    /**
     * Company address
     */
    address?: string;
    /**
     * Company name
     */
    name: string;
    /**
     * Default Oil Temperature Formula ID
     */
    oilTemperatureID?: number;
}
