/**
 * dev-ops-aware-users-api-blue
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T19:43:48Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { NotificationAck } from './notificationAck';


/**
 * Represents a notification to be displayed to the user
 */
export interface Notification { 
    acknowledgement?: NotificationAck;
    /**
     * Indicates whether or not the condition that caused the notification is on going.
     */
    active: boolean;
    /**
     * Type-specific context about the notification
     */
    context?: any;
    /**
     * Indicates that the notification has been seen by a user. A notification becomes seen by making a call to the seeNotifications API.
     */
    hasBeenSeen: boolean;
    /**
     * database row ID of the notification, can be sent back to inform the server a notification has been seen
     */
    id: number;
    /**
     * Unix timestamp of when the notification was generated
     */
    timestamp: number;
    /**
     * the type of notification that this is
     */
    type: string;
}
