<button mat-button class="notification-bell" [matMenuTriggerFor]="notificationList" (click)="markVisibleAsSeen()">
    <mat-icon matBadge="!" matBadgeColor="warn" [matBadgeHidden]="totalUnseenCount <= 0" aria-hidden="false">notifications</mat-icon>
</button>
<mat-menu #notificationList="matMenu">
    <div class="notifications-header">
        <div class="notifications-header-text">
            Notifications
        </div>
        <div class="see-all-link">
            <a [routerLink]="[ '/notifications' ]">See all</a>
        </div>
    </div>

    <div class="notifications-table">
        <div *ngFor="let row of notifications" (click)="onNotificationClicked(row)"
            class="notification-container">
            <div class="notification-row-header">
                <mat-icon [color]="(row.notification.active) ? 'warn':''">
                    {{getIconForNotification(row.notification)}}</mat-icon>
                <span class="notification-header-text">{{row.title}}</span>
            </div>
            <div [innerHTML]="row.description"></div>
            <span class="notification-date-text">Occurred at {{row.notification.timestamp * 1000 | date : 'MMM dd,yyyy HH:mm:ss'}}</span>
        </div>
    </div>
</mat-menu>
