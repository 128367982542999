/**
 * dev-ops-aware-devices-api-blue
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T19:45:32Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FirmwareDescription } from './firmwareDescription';


/**
 * Statistics about how many devices have a particular firmware version installed
 */
export interface DeviceFirmwareInstallCount { 
    /**
     * A count of the devices which currently have this firmware image loaded.
     */
    deviceCount: number;
    firmwareDescription: FirmwareDescription;
    /**
     * The ID of the firmware version that the stats relate to. Please note, this is the firmware version ID, not the firmware Image ID. If you need to match this with a firmware image, you should compare the firmwareDescription field to the one from the image you are attempting to match.
     */
    firmwareVersionID: number;
}
