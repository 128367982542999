import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { Observable, filter, map } from 'rxjs'

export function pathChanges(router: Router): Observable<string>
{
    return router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map((value:NavigationEnd) => {
            return value.url
        })
    )
}

export function getDocumentationPath(rootRoute: ActivatedRoute): string
{
    // We need to traverse the activated route to get the full path here
    let path = ""

    let currentSnapshot = rootRoute.snapshot

    while (true)
    {
        // The router includes a bunch of empty paths, for each time there is a potential branch
        // we don't really need to include these

        if (currentSnapshot.routeConfig.path.length > 0)
        {
            path += "/" + currentSnapshot.routeConfig.path
        }

        if (!currentSnapshot.firstChild)
        {
            break
        }

        currentSnapshot = currentSnapshot.firstChild
    }

    return path
}
