/**
 * dev-ops-aware-devices-api-blue
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T19:45:32Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * describes the data required to register a single device
 */
export interface RegisterDeviceInput { 
    /**
     * A human-readable description of the device to register. This field is optional.
     */
    description?: string;
    /**
     * The IMEI of the device to register.
     */
    imei: string;
    /**
     * The serial number of the device to register.
     */
    serialNumber: string;
}
