/**
 * dev-ops-aware-admin-api-blue
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T19:48:34Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { OilTemperatureFormula } from './oilTemperatureFormula';


export interface OilTemperatureFormulas { 
    /**
     * The ID of the formula that should be used by default, if one has been set for the system.
     */
    defaultFormulaID?: number;
    /**
     * An array of oil temperature formulas that are available in the OpsAware system
     */
    formulas: Array<OilTemperatureFormula>;
}
