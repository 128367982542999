import { CanActivateFn, Router } from '@angular/router'
import { LoggedInUserService } from 'src/app/services/logged-in-user/logged-in-user.service'
import { inject } from '@angular/core'
import { firstValueFrom } from 'rxjs'

export const isSysAdminGuard: CanActivateFn = async (route, state) =>
{

    const router = inject(Router)

    try
    {
        const loggedInUser = inject(LoggedInUserService)

        const overview = await firstValueFrom(loggedInUser.overview())

        if (!overview.isSystemAdmin)
        {
            router.navigate(['notfound'])
        }

        return overview.isSystemAdmin
    }
    catch (error)
    {
        return false
    }
}
