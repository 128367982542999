/**
 * dev-ops-aware-admin-api-blue
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T19:48:34Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DeviceError } from './deviceError';


/**
 * A collection of device errors
 */
export interface DeviceErrorCollection { 
    deviceErrors?: Array<DeviceError>;
}
