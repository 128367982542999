/**
 * dev-ops-aware-users-api-blue
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T19:43:48Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Subscription } from './subscription';


export interface SubscriptionCollection extends Array<Subscription> { 
}
