import { Route, Routes } from '@angular/router';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { FooterOnlyLayoutComponent } from './layout/footer-only-layout/footer-only-layout.component';
import { UserAuthService } from './services/userAuth/user-auth.service';
import { SysAdminLayoutComponent } from './layout/sysadmin-layout/layout.component'
import { isLoggedInGuard } from './guards/is-logged-in/is-logged-in.guard'
import { isSysAdminGuard } from './guards/is-sys-admin/is-sys-admin.guard'
import { NotFoundComponent } from './pages/not-found/not-found.component'

export const MAIN_ROUTE: Route = {
    path: '',
    component: MainLayoutComponent,
    children: [
        { path: 'dashboard', loadComponent: () => import('./pages/dashboard/dashboard.component').then(m => m.DashboardComponent) },
        { path: 'groups', loadChildren: () => import('./pages/groups/routes').then(m => m.GROUPS_ROUTING) },
        { path: 'group', redirectTo: 'groups' },
        { path: 'devices', loadChildren: () => import('./pages/devices/routes').then(m => m.DEVICES_ROUTES) },
        { path: 'device', redirectTo: 'devices' },
        { path: 'logs', loadComponent: () => import('./pages/logs/logs.component').then(m => m.LogsComponent) },

        { path: 'user', loadComponent: () => import('./pages/user-profile/user-profile.component').then(m => m.UserProfileComponent) },
        { path: 'map', loadComponent: () => import('./pages/map/map.component').then(m => m.MapComponent) },
        { path: 'notifications', loadComponent: () => import('./pages/notifications/notifications.component').then(m => m.NotificationsComponent) },
        { path: 'companies', loadChildren: () => import('./pages/companies/routing').then(m => m.COMPANIES_ROUTES) },
        { path: "**", loadComponent: () => import('./pages/not-found/not-found.component').then(m => m.NotFoundComponent) }
    ],
    canActivate: [isLoggedInGuard]
}

export const APP_ROUTES: Routes = [
    {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
    },
    {
        path: '',
        component: SysAdminLayoutComponent,
        children: [
            { path: 'sysadmin', loadChildren: () => import('./pages/sysadmin/routes').then(m => m.SYSADMIN_ROUTES) }
        ],
        canActivate: [isSysAdminGuard]
    },
    {
        path: '',
        component: FooterOnlyLayoutComponent,
        children: [
            { path: 'login', loadChildren: () => import('./pages/login/routes').then(mod => mod.LOGIN_ROUTES) },
            { path: 'subscriptions', loadChildren: () => import('./pages/subscription-verification/routes').then(m => m.SUBSCRIPTION_VERIFICATION_ROUTES) }
        ]
    },
    MAIN_ROUTE,
];
