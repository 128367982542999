/**
 * dev-ops-aware-devices-api-blue
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T19:45:32Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DeviceNotificationFilterSet } from './deviceNotificationFilterSet';
import { DeviceNotificationFilters } from './deviceNotificationFilters';
import { Rectangle } from './rectangle';


/**
 * a set of parameters to be used to filter for specific devices.
 */
export interface DeviceFilters { 
    /**
     * Filter for including only devices which belong to a company with one of the specified IDs
     */
    companyIDs?: Array<number>;
    /**
     * Include only results which are not part of the groups with the specified IDs.
     */
    excludedGroups?: Array<number>;
    /**
     * Include only results that have these specific IDs.
     */
    ids?: Array<number>;
    /**
     * Include only results belong to groups with the specified IDs
     */
    includedGroups?: Array<number>;
    notificationFilters?: DeviceNotificationFilters;
    /**
     * The operator to use to filter by notifications.
     */
    notificationMode?: DeviceFilters.NotificationModeEnum;
    notifications?: DeviceNotificationFilterSet;
    /**
     * Filter for devices which have reported after the specified unix timestamp.
     */
    reportedAfter?: number;
    /**
     * Full match text-search for devices who's serial number matches the specified string
     */
    serial?: string;
    /**
     * Include only results which have been assigned the specified transformer types.
     */
    transformerTypes?: Array<number>;
    userID?: number;
    withinRectangle?: Rectangle;
}
export namespace DeviceFilters {
    export type NotificationModeEnum = 'and' | 'or';
    export const NotificationModeEnum = {
        And: 'and' as NotificationModeEnum,
        Or: 'or' as NotificationModeEnum
    };
}
