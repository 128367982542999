/**
 * dev-ops-aware-users-api-blue
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T19:43:48Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CompanyUserInvite } from './companyUserInvite';


/**
 * A collection of users to add to a company
 */
export interface CompanyUserInviteCollection { 
    /**
     * The emails of the user's to invite to the company
     */
    users: Array<CompanyUserInvite>;
}
