/**
 * dev-ops-aware-users-api-blue
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T19:43:48Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Company } from './company';


/**
 * A description of a collection of companies
 */
export interface CompanyCollection { 
    /**
     * An array of the results of the device group search
     */
    results: Array<Company>;
    /**
     * A count of how many possible results there are, unbounded by page size.
     */
    totalResultCount: number;
}
