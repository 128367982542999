import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-footer-only-layout',
    templateUrl: './footer-only-layout.component.html',
    styleUrls: ['./footer-only-layout.component.css'],
    standalone: true,
    imports: [RouterOutlet]
})
export class FooterOnlyLayoutComponent implements OnInit {

  constructor() { 
  }

  ngOnInit() {
  }

}
