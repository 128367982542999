import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment'
import { Subscription } from 'rxjs'
import { LoggedInUserService } from 'src/app/services/logged-in-user/logged-in-user.service'
import { UserAuthService } from 'src/app/services/userAuth/user-auth.service'
import { defaultDateFormat } from 'src/app/utilities/formatting/time'

@Pipe({
    name: 'timeFormatting',
    pure: false,
    standalone: true
})
export class TimeFormattingPipe implements PipeTransform {

    settingsSubscription: Subscription

    timeZone: string = undefined;

    constructor(private loggedInUser: LoggedInUserService)
    {
        this.settingsSubscription = this.loggedInUser.overview().subscribe((settings) => {
            this.timeZone = settings.timeZone;
        });
    }

    transform(value: number | string | Date): string
    {
        if (typeof value === 'number')
        {
            value *= 1000
        }

        try {
            let formattedText = moment.tz(value, this.timeZone).format(defaultDateFormat);

            return formattedText
        }
        catch (error) {

            console.error(error);

            return "-";
        }
    }

    ngOnDestroy()
    {
        this.settingsSubscription.unsubscribe();
    }
}
