/**
 * dev-ops-aware-devices-api-blue
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T19:45:32Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BatteryState } from './batteryState';
import { DeviceNotificationState } from './deviceNotificationState';
import { FirmwareDescription } from './firmwareDescription';
import { Location } from './location';
import { MeasurementFrame } from './measurementFrame';
import { ModemInfo } from './modemInfo';
import { SignalQuality } from './signalQuality';
import { TransformerType } from './transformerType';


/**
 * Information overview of a device
 */
export interface DeviceOverview { 
    /**
     * The most recent measurement of the device's battery.
     */
    batteryState?: BatteryState;
    /**
     * Indicates whether the device is in the process of being deleted or not
     */
    beingDeleted?: boolean;
    /**
     * The ID of the company that the device belongs to
     */
    companyID?: number;
    /**
     * A human-readable description of the device
     */
    description?: string;
    /**
     * A description of the firmware currently loaded on the device
     */
    firmwareDescriptions?: { [key: string]: FirmwareDescription; };
    /**
     * The device's ID
     */
    id: number;
    /**
     * The unix timestamp of when the device last reported in
     */
    lastReportTimestamp?: number;
    /**
     * The last reported GPS location of the device. This may be null if the device has not reported in yet.
     */
    location?: Location;
    /**
     * Information about the device's modem
     */
    modemInfo?: ModemInfo;
    /**
     * The most recent measurement frame taken by the device
     */
    mostRecentMeasurementFrame?: MeasurementFrame;
    /**
     * Indicates whether or not the device currently has any of a set of notifications.
     */
    notifications: DeviceNotificationState;
    /**
     * The device's serial number
     */
    serialNumber: string;
    /**
     * The most recent Signal Quality measurement taken by the device
     */
    signalQuality?: SignalQuality;
    /**
     * The ID of the firmware image that the SignalPort is being updated to.
     */
    targetAppUpdateImage?: number;
    /**
     * The transformer type currently assigned to the device
     */
    transformerType?: TransformerType;
}
