/**
 * dev-ops-aware-devices-api-blue
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T19:45:32Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Information about a device's modem
 */
export interface ModemInfo { 
    /**
     * The version of the modem firmware
     */
    fwVersion?: string;
    /**
     * The timestamp at which the notification was acknowledged
     */
    iccid?: string;
    /**
     * The ID of the user that acknowledged the notification
     */
    imei?: string;
}
