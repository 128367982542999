import { enableProdMode, importProvidersFrom } from '@angular/core';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { MarkdownModule } from 'ngx-markdown';
import { MatButtonModule } from '@angular/material/button';
import { provideAnimations } from '@angular/platform-browser/animations';
import { withInterceptorsFromDi, provideHttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiModule as HelpAPIModule } from './app/ops-aware-help-api/api.module';
import { ApiModule as LogsAPIModule } from './app/ops-aware-logs-api/api.module';
import { ApiModule as UsersAPIModule } from './app/ops-aware-users-api/api.module';
import { ApiModule as DevicesAPIModule } from './app/ops-aware-devices-api/api.module';
import { ApiModule as AdminAPIModule } from './app/ops-aware-admin-api/api.module';
import { provideRouter } from '@angular/router';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { BASE_PATH as HELP_BASE_PATH } from './app/ops-aware-help-api';
import { BASE_PATH as LOGS_BASE_PATH } from './app/ops-aware-logs-api';
import { BASE_PATH as USERS_BASE_PATH } from './app/ops-aware-users-api';
import { BASE_PATH as DEVICES_BASE_PATH } from './app/ops-aware-devices-api';
import { BASE_PATH as ADMIN_BASE_PATH } from './app/ops-aware-admin-api';
import { APP_ROUTES } from './app/app-routes'
import { AuthInterceptor } from './app/interceptors/auth.interceptor'

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, AdminAPIModule, DevicesAPIModule, UsersAPIModule, LogsAPIModule, HelpAPIModule, MatButtonModule, MarkdownModule.forRoot()),
        { provide: ADMIN_BASE_PATH, useValue: environment.adminAPIURL },
        { provide: DEVICES_BASE_PATH, useValue: environment.devicesAPIURL },
        { provide: USERS_BASE_PATH, useValue: environment.userAPIURL },
        { provide: LOGS_BASE_PATH, useValue: environment.logsAPIURL },
        { provide: HELP_BASE_PATH, useValue: environment.helpAPIURL },
        provideRouter(APP_ROUTES),
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }
    ]
})
  .catch(err => console.error(err));
