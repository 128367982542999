/**
 * dev-ops-aware-devices-api-blue
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T19:45:32Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MeasurementStats } from './measurementStats';
import { TempPatrolMeasurement } from './tempPatrolMeasurement';


/**
 * A measurement frame
 */
export interface MeasurementFrame { 
    /**
     * The ambient temperature that was recorded by the SignalPort during the measurement frame
     */
    ambientTemperature?: MeasurementStats;
    /**
     * The device ID
     */
    deviceID: number;
    /**
     * The unix timestamp of when the measurement ended.
     */
    endTimestamp: number;
    /**
     * Indicates whether or not the device is powered on mains or battery
     */
    hasMainsPower?: boolean;
    /**
     * The ID of the measurement frame
     */
    id: number;
    /**
     * The oil temperatures recorded during the measurement frame mapped to the IDs of the peripherals that took them.
     */
    oilTemperatures?: { [key: string]: TempPatrolMeasurement; };
    /**
     * The unix timestamp of when the measurement was reported to the cloud
     */
    reportedAt?: number;
    /**
     * The unix timestamp of when the measurement began
     */
    startTimestamp: number;
}
