import { Component, OnInit, HostListener, ViewChild, OnDestroy } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav'
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { SideNavComponent } from '../../components/layout/side-nav/side-nav.component';
import { HeaderComponent } from '../../components/layout/header/header.component';
import { MenuOption, MenuOptionGrouping } from 'src/app/menu-option'
import { DocumentationDocumentComponent } from 'src/app/components/documentation/documentation-document/documentation-document.component'
import { getDocumentationPath, pathChanges } from 'src/app/utilities/documentation/path'
import { Subscription} from 'rxjs'
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'
import { MatDialog, MatDialogModule } from '@angular/material/dialog'
import { TermsAndConditionsComponent } from 'src/app/components/terms-and-conditions/terms-and-conditions.component'
import { LoggedInUserService } from 'src/app/services/logged-in-user/logged-in-user.service'
import { NgIf } from '@angular/common'
import { BaseMenuLayoutComponent } from '../base-menu-layout/base-menu-layout.component'

@Component({
    selector: 'app-main-layout',
    templateUrl: './main-layout.component.html',
    styleUrls: ['./main-layout.component.css'],
    standalone: true,
    imports: [HeaderComponent, MatSidenavModule, SideNavComponent, RouterOutlet, DocumentationDocumentComponent, MatIconModule, MatButtonModule, MatDialogModule, TermsAndConditionsComponent, NgIf, BaseMenuLayoutComponent]
})
export class MainLayoutComponent implements OnInit
{


    viewerMenuOptions: MenuOption[] = [

        { name: "Dashboard", routerSelector: "/dashboard" },
        { name: "Map", routerSelector: "/map" },
        { name: "SignalPorts", routerSelector: "/devices" }
    ]

    adminMenuOptions: MenuOption[] = [

        { name: "Dashboard", routerSelector: "/dashboard" },
        { name: "Map", routerSelector: "/map" },
        { name: "SignalPorts", routerSelector: "/devices" },
        { name: "Groups", routerSelector: "/groups" },
        { name: "Configuration Logs", routerSelector: "/logs" }
    ]

    sysAdminMenuOptions: MenuOption[] = [
        { name: "System Administration", routerSelector: "/sysadmin" }
    ]

    opened: boolean = true;

    menuOptions:MenuOptionGrouping[]



    constructor(
        private loggedInUser: LoggedInUserService,
        private router:Router,
        private route: ActivatedRoute,
        private dialog: MatDialog
    ) {


        this.loggedInUser.overview().subscribe((overview) =>
        {
            let navigationOptions: MenuOption[]

            let companyNavigationOptions: MenuOption[] = []

            var userIsAdmin = false
            for (let companyId in overview.associations)
            {
                if (overview.associations[companyId].role == "admin")
                {
                    userIsAdmin = true
                }

                companyNavigationOptions.push({
                    name: overview.associations[companyId].companyName,
                    routerSelector: `/companies/${companyId}`
                })
            }

            companyNavigationOptions.sort((a, b) => {
                return a.name.localeCompare(b.name)
            })

            if (userIsAdmin)
            {
                navigationOptions = this.adminMenuOptions
            }
            else
            {
                navigationOptions = this.viewerMenuOptions
            }

            this.menuOptions = [
                {
                    name: "Navigation",
                    options: navigationOptions
                },
                {
                    options: companyNavigationOptions
                }
            ]

            if (overview.isSystemAdmin)
            {
                this.menuOptions.push({
                    options: this.sysAdminMenuOptions
                })
            }

        });

        this.loggedInUser.shouldShowTermsAndConditions.subscribe((shouldShow) => {

            if (shouldShow)
            {
                this.showTerms()
            }

        })
    }

    /**
     * Checks and displays the terms and conditions dialog if necessary.
     */
    showTerms()
    {
        // HACK: Terms and conditions are not ready yet, didn't realize this until deployment
        //const dialogRef = this.dialog.open(TermsAndConditionsComponent);
    }

    ngOnInit()
    {

    }
}
