/**
 * dev-ops-aware-devices-api-blue
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T19:45:32Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FirmwareDescription } from './firmwareDescription';


/**
 * describes a firmware image
 */
export interface FirmwareImage { 
    /**
     * The base address of the firmware image
     */
    baseAddress: number;
    firmwareDescription: FirmwareDescription;
    /**
     * The format of the firmware image
     */
    format: number;
    /**
     * The length of the firmware image in bytes
     */
    lengthInBytes: number;
}
