import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { NgIf } from '@angular/common'
import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatSidenavModule } from '@angular/material/sidenav'
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router'
import { Subscription } from 'rxjs'
import { DocumentationDocumentComponent } from 'src/app/components/documentation/documentation-document/documentation-document.component'
import { HeaderComponent } from 'src/app/components/layout/header/header.component'
import { SideNavComponent } from 'src/app/components/layout/side-nav/side-nav.component'
import { SysAdminHeaderComponent } from 'src/app/components/layout/sysadmin-header/header.component'
import { MenuOption } from 'src/app/menu-option'
import { getDocumentationPath, pathChanges } from 'src/app/utilities/documentation/path'

@Component({
  selector: 'app-base-menu-layout',
  standalone: true,
  imports: [SideNavComponent, RouterOutlet, SysAdminHeaderComponent, HeaderComponent, MatSidenavModule, DocumentationDocumentComponent, MatIconModule, NgIf, MatButtonModule],
  templateUrl: './base-menu-layout.component.html',
  styleUrl: './base-menu-layout.component.css'
})
export class BaseMenuLayoutComponent {

    @Input() menuOptions: MenuOption[]

    @Input() showingHelp: boolean

    @Input() helpAvailable: boolean

    @Input() currentDocPath: string

    @Input() headerPath: string

    @ViewChild(DocumentationDocumentComponent) document!: DocumentationDocumentComponent

    private helpSub: Subscription

    isMobile = false

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private breakpointObserver: BreakpointObserver
    ) {
        this.currentDocPath = getDocumentationPath(route)

        this.helpSub = pathChanges(router).subscribe((value) => {
            this.currentDocPath = getDocumentationPath(route)
        })

        this.breakpointObserver.observe([Breakpoints.Handset]).subscribe((breakpoint) => {
            this.isMobile = breakpoint.matches
        })
    }

    ngAfterViewInit(): void
    {

    }

    helpDocumentLoaded(foundPage: boolean)
    {
        this.helpAvailable = foundPage
    }

    ngOnDestroy(): void
    {
        this.helpSub.unsubscribe()
    }
}
