<div>
    <mat-nav-list>
        @for (menuGrouping of menuOptions; track menuGrouping.options) {
            <h3 matSubheader *ngIf="menuGrouping.name; else noNameGrouping">{{menuGrouping.name}}</h3>
            <a mat-list-item *ngFor="let menuOption of menuGrouping.options" [routerLink]="[menuOption.routerSelector]">
                <span matLine>{{menuOption.name}}</span>
            </a>
        }
    </mat-nav-list>
</div>
<div>
    <mat-divider></mat-divider>
    <div class="version-label">
        OpsAware v{{webAppVersion}}
    </div>
</div>
<ng-template #noNameGrouping>
    <mat-divider></mat-divider>
</ng-template>
