/**
 * dev-ops-aware-help-api-blue
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T19:52:05Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * A page of documentation
 */
export interface DocumentationPagePath { 
    /**
     * The user ID of the last user to edit the page
     */
    lastEditByUserID: number;
    /**
     * The unix timestamp of when the document was last edited
     */
    lastEditOnTimestamp: number;
    /**
     * The path of the documentation page
     */
    path: string;
}
