<div #headerContainer class="header-container">
    <app-header header [showMenuButton]="isMobile" (helpToggled)="showingHelp = !showingHelp" [helpAvailable]="helpAvailable" [headerPath]="headerPath" [showTimeZoneSelector]="!isMobile">
        <ng-content select="[subHeader]" subHeader></ng-content>
        <app-side-nav [items]="menuOptions"></app-side-nav>
    </app-header>
</div>
@if (showingHelp) {
<div class="help-backing">
    <div class="help-pullout">
        <button class="close-button" mat-button (click)="showingHelp=false">
            <mat-icon>close</mat-icon>
        </button>
        <app-documentation-document [path]="currentDocPath" (documentLoaded)="helpDocumentLoaded($event)"></app-documentation-document>
    </div>
</div>
}
<div class="content-container">
    @if (menuOptions && !isMobile) {
        <div class="side-nav-content">
            <app-side-nav [items]="menuOptions"></app-side-nav>
        </div>
        }
        <div class="projected-content-container">
            <ng-content select="[content]"></ng-content>
        </div>
</div>

