import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { getNotificationDescription, getNotificationTitle } from 'src/app/utilities/notifications/descriptions';
import { Notification } from 'src/app/ops-aware-users-api';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-notification-snackbar',
    templateUrl: './notification-snackbar.component.html',
    styleUrls: ['./notification-snackbar.component.css'],
    standalone: true,
    imports: [MatButtonModule, MatIconModule]
})
export class NotificationSnackbarComponent implements OnInit {

    description:string;

    heading:string;

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: Notification[], private barRef: MatSnackBarRef<NotificationSnackbarComponent>, private router: Router) {

        if (data.length > 1)
        {
            this.heading = "New Notifications";
            this.description = `${data.length} new notifications have been generated.`;
        }
        else if (data.length == 1)
        {
            this.heading = getNotificationTitle(data[0]);
            this.description = getNotificationDescription(data[0], false);
        }
        else
        {
            barRef.dismiss();
        }
    }

    ngOnInit()
    {
    }

    close()
    {
        this.barRef.dismiss();
    }

    clicked()
    {
        this.router.navigate(["notifications"]);
    }
}
