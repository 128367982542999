import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LMarkdownEditorModule, MdEditorOption, UploadResult } from 'ngx-markdown-editor'
import { FormsModule } from '@angular/forms'
import { DefaultService as HelpApi, DocumentationPage } from '../../../ops-aware-help-api'
import { BehaviorSubject, firstValueFrom, from, lastValueFrom, of, switchMap, tap } from 'rxjs'
import { MatButtonModule } from '@angular/material/button'
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MarkdownModule } from 'ngx-markdown'
import { MatIconModule } from '@angular/material/icon'
import { MatDividerModule } from '@angular/material/divider'
import { TimeFormattingPipe } from 'src/app/pipes/time/time-formatting.pipe'
import { HttpClient } from '@angular/common/http'
import { DefaultService } from 'src/app/ops-aware-admin-api'
import { LoggedInUserService } from 'src/app/services/logged-in-user/logged-in-user.service'

@Component({
    selector: 'app-documentation-document',
    standalone: true,
    templateUrl: './documentation-document.component.html',
    styleUrls: ['./documentation-document.component.css'],
    providers: [TimeFormattingPipe],
    imports: [
        CommonModule,
        LMarkdownEditorModule,
        FormsModule,
        MatButtonModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MarkdownModule,
        MatIconModule,
        MatDividerModule,
        MatSnackBarModule,
        TimeFormattingPipe
    ]
})
export class DocumentationDocumentComponent {

    loading = new BehaviorSubject(false)

    page?: DocumentationPage

    content?: string = undefined

    mode: string = "preview"

    options: MdEditorOption = {
        showBorder: true,
        resizable: false,
    }

    editorHeight = '320px'

    @HostListener('window:resize', ['$event'])
    onResize(event)
    {
        this.setEditorHeight(event.target.innerHeight)
    }

    setEditorHeight(height: number)
    {

        this.editorHeight = (height - 256).toString() + "px"

    }

    private _path?:string = undefined

    createdByUserDescription:string = undefined

    @Output() documentLoaded = new EventEmitter<boolean>()

    @Input() fillScreen = false

    private _version: number

    @Input()
    get version(): number
    {
        return this._version
    }
    set version(value: number)
    {
        this._version = value

        this.refreshPage()
    }

    @Input()
    get path(): string
    {
        return this._path
    }
    set path(value: string)
    {
        this._path = value

        this.refreshPage()
    }

    private async refreshPage()
    {
        this.loading.next(true)

        try
        {
            const userOverview = await firstValueFrom(this.loggedInUser.overview())

            this.page = await firstValueFrom(this.helpAPI.getPage(this._path, this._version?.toString()))

            if (userOverview.isSystemAdmin)
            {            const users = await firstValueFrom(this.adminAPI.getUsers(undefined, undefined, undefined, undefined, undefined, this.page.createdBy.toString()))

                if (users.users.length > 0)
                {
                    this.createdByUserDescription = users.users[0].email
                }
                else
                {
                    this.createdByUserDescription = "Unknown"
                }
            }


            this.content = this.page.document


        }
        catch (error)
        {
            // A 404 is an expected error, if the page does not exist
            if (error.status == 404)
            {
                this.content = undefined
                this.page = undefined
            }
        }
        finally
        {
            this.documentLoaded.next((this.page != null))
            this.loading.next(false)
        }
    }

    constructor(
        private helpAPI: HelpApi,
        private adminAPI: DefaultService,
        private snackBar: MatSnackBar,
        private http: HttpClient,
        private loggedInUser: LoggedInUserService
    )
    {

        this.doUpload = this.doUpload.bind(this)

        this.refreshPage()
    }

    async doUpload(files: Array<File>): Promise<Array<UploadResult>>
    {
        let results: UploadResult[] = []

        // Grab URLs from the help API for each of the files
        for (let i = 0; i < files.length; i++)
        {
            const url = await firstValueFrom(this.helpAPI.createUploadURL({
                filename: files[i].name
            }))


            await firstValueFrom(
                this.http.put(
                    url.url,
                    files[i],
                    {
                        headers: {noAuth:"true"}
                    }
                )
            )

            results.push({
                name: files[i].name,
                url: url.url.split("?")[0],
                isImg: true
            })
        }

        return results
    }

    preRenderFunc(content: string)
    {
        return content
    }

    postRenderFunc(content: string)
    {
        return content
    }

    onEditorLoaded(event: any)
    {

    }

    onPreviewDomChanged(event: any)
    {

    }

    createPage()
    {
        this.content = ""

        this.editPage()
    }

    editPage()
    {
        this.mode = "editor"

        this.setEditorHeight(innerHeight)
    }

    endEdit()
    {
        this.mode = "preview"
    }

    discardPage()
    {
        this.content = this.page?.document

        this.endEdit();
    }

    async savePage()
    {
        if (!this.content)
        {
            return
        }

        this.loading.next(true)

        try
        {
            await firstValueFrom(this.helpAPI.createOrUpdatePage(this.path, {
                document: this.content
            }))

            await this.refreshPage()

            this.endEdit()
        }
        catch(error)
        {
            this.snackBar.open("Error occurred while saving document: " + error.error.message)
        }
        finally
        {
            this.loading.next(false)
        }

    }

    ngAfterViewInit(): void
    {
        this.setEditorHeight(innerHeight)
    }
}
