/**
 * dev-ops-aware-admin-api-blue
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T19:48:34Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { OilTemperatureFormulaSettings } from './oilTemperatureFormulaSettings';


export interface OilTemperatureFormula extends OilTemperatureFormulaSettings { 
    /**
     * The number of companies that are using the formula
     */
    companiesUsingFormulaCount?: number;
    /**
     * The number of devices that are using the formula
     */
    devicesUsingFormulaCount?: number;
    /**
     * The unique ID of the formula
     */
    id: number;
}
