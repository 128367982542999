import { Directive, ElementRef, HostBinding } from '@angular/core'

@Directive({
    selector: '[appMainHeader]',
    standalone: true,
    host: {
        '[style.display]': '"block"',
        '[style.margin-block-start]': '"0.4em"',
        '[style.margin-block-end]': '"0.4em"',
        '[style.margin-inline-start]': '"0px"',
        '[style.margin-inline-end]': '"0px"',
        '[style.font-weight]': '"bold"',
    }
})
export class MainHeaderDirective
{
    @HostBinding('style.font-size.em') fontSize = 1.5

    constructor(
        private elementRef: ElementRef
    ) {
        const tagName = this.elementRef.nativeElement.tagName.toLowerCase();

        switch(tagName)
        {
            case "h1":
                this.fontSize = 1.7
                break
            case "h2":
                this.fontSize = 1.5
                break
            case "h3":
                this.fontSize = 1.3
                break
            case "h4":
                this.fontSize = 1.17
                break
            case "h5":
                this.fontSize = 1
                break
            case "h6":
                this.fontSize = 0.875
                break
        }
    }

}
