/**
 * dev-ops-aware-devices-api-blue
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T19:45:32Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FirmwareImage } from './firmwareImage';


/**
 * Describes an available firmware update
 */
export interface FirmwareUpdateV2 { 
    /**
     * Whether or not the firmware image is active and will be shown as available to users.
     */
    active: boolean;
    /**
     * A link to the PDF firmware changelog for this URL
     */
    changeLogUrl?: string;
    /**
     * The firmware image's ID
     */
    id: number;
    image: FirmwareImage;
    /**
     * Indicates whether the image is in staging or not
     */
    staging: boolean;
    /**
     * The timestamp of when the firmware image was added.
     */
    uploadTimestamp: number;
}
