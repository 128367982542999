<div class="logo-container">
    <button mat-button class="menu-button" *ngIf="showMenuButton" [matMenuTriggerFor]="navigationMenu"><mat-icon>menu</mat-icon></button>

    <a [href]="headerPath"><img src="assets/IFD_Logo_nobg.png" *ngIf="!showMenuButton" alt="IFD Corporation logo"></a>
    <ng-content select="[subHeader]">

    </ng-content>
</div>

<div class="header-tool-bar">
    @if (showTimeZoneSelector) {
    <div class="time-zone-container">
        <div *ngIf="timeZoneChanging | async">
            <mat-spinner [diameter]="32"></mat-spinner>
        </div>

            <div class="time-zone-selector-container">
                <div class="time-zone-label">
                    Time Zone
                </div>
                <select class="time-zone-selector" #timeZoneSelector
                    (change)="timeZoneChanged(timeZoneSelector.value)"
                    [disabled]="timeZoneChanging | async"
                    matTooltip="Select the timeszone that all the timestamps on the website will be displayed in.">
                    <optgroup *ngFor="let timeZoneGroup of timeZoneGroups" [label]="timeZoneGroup.name">
                        <option *ngFor="let timeZone of timeZoneGroup.timeZones" [value]="timeZone.value">{{timeZone.label}}</option>
                    </optgroup>
                </select>
            </div>
            <button mat-icon-button (click)="setToLocalTimeZone()" matTooltip="Set your timezone to the local timezone">
                <mat-icon>
                    location_searching
                </mat-icon>
            </button>

    </div>
    }
    <mat-divider [vertical]="true"></mat-divider>
    <app-toolbar-notifications></app-toolbar-notifications>
    <mat-divider [vertical]="true"></mat-divider>
    <app-toolbar-user-info class="user-menu-button"></app-toolbar-user-info>
    <mat-divider [vertical]="true"></mat-divider>
    <div class="help-button-container">
        <button class="menu-button" (click)="helpToggled.next()"  *ngIf="helpAvailable || userIsSystemAdmin"><mat-icon>help_outline</mat-icon></button>
    </div>
</div>
<mat-menu #navigationMenu="matMenu">
    <ng-content></ng-content>
</mat-menu>

