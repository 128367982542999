/**
 * dev-ops-aware-devices-api-blue
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T19:45:32Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * A set of filters used to filter for devices with specific notification states
 */
export interface DeviceNotificationFilterSet { 
    disconnectedPeripheral?: boolean;
    extremeOverload?: boolean;
    lostMainsPower?: boolean;
    lowBattery?: boolean;
    lowImpedanceFault?: boolean;
    lowSignal?: boolean;
    missingReport?: boolean;
    overload?: boolean;
    restoredMainsPower?: boolean;
}
