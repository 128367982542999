/**
 * dev-ops-aware-users-api-blue
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T19:43:48Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Location } from './location';


/**
 * Passed to the update users function to provide the fields to be updated
 */
export interface UserUpdate { 
    /**
     * Indicates if the user has agreed to the terms and conditions agreement.
     */
    acceptedTerms?: boolean;
    /**
     * Sets the user's first name, if included.
     */
    firstName?: string;
    /**
     * The location of the user's home position on a map
     */
    homeLocation?: Location;
    /**
     * The Google Maps zoom value of the user's home position
     */
    homeZoom?: number;
    /**
     * Sets the user's last name, if included.
     */
    lastName?: string;
    /**
     * Sets the user's preferred time zone. The only consequence of this is that the preferred time zone will show up when the user's information is fetched. This will not convert fetched measurements to the specified time zone. All time zone descriptors from the \"tz\" database will be accepted.
     */
    timeZone?: string;
}
