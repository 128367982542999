/**
 * dev-ops-aware-help-api-blue
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-11-01T19:52:05Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * A page of documentation
 */
export interface DocumentationPage { 
    /**
     * The ID of the user that created this revision of the document
     */
    createdBy: number;
    /**
     * The unix timestamp of when the current revision was created
     */
    createdOn: number;
    /**
     * The body of the document. It is expected that the document will be in Markdown, though that is not explicitly checked for or required.
     */
    document: string;
}
