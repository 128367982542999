import { NgIf, NgFor, AsyncPipe } from '@angular/common'
import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core'
import * as moment from 'moment-timezone'
import { BehaviorSubject, Subscription } from 'rxjs'
import { SideNavComponent } from '../side-nav/side-nav.component'
import { ToolbarUserInfoComponent } from '../toolbar-user-info/toolbar-user-info.component'
import { ToolbarNotificationsComponent } from '../toolbar-notifications/toolbar-notifications.component'
import { MatDividerModule } from '@angular/material/divider'
import { ReactiveFormsModule } from '@angular/forms'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import { MatButtonModule } from '@angular/material/button'
import { MatTooltipModule } from '@angular/material/tooltip'
import { DocumentationDocumentComponent } from '../../documentation/documentation-document/documentation-document.component'
import { LoggedInUserService } from 'src/app/services/logged-in-user/logged-in-user.service'

interface TimeZone
{
    label: string
    value: string
}

interface TimeZoneGroup
{
    name: string
    timeZones: TimeZone[]
}

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
    standalone: true,
    imports: [
        NgIf,
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        MatProgressSpinnerModule,
        NgFor,
        ReactiveFormsModule,
        MatDividerModule,
        ToolbarNotificationsComponent,
        ToolbarUserInfoComponent,
        SideNavComponent,
        AsyncPipe,
        MatTooltipModule,
        DocumentationDocumentComponent
    ]
})
export class HeaderComponent implements OnInit
{
    timeZoneChanging = new BehaviorSubject<boolean>(false);

    timeZoneGroups: TimeZoneGroup[]

    pathSub: Subscription

    userIsSystemAdmin: boolean

    @ViewChild('timeZoneSelector') timeZoneSelector: ElementRef<HTMLSelectElement>

    @Input() showMenuButton: boolean = false;

    @Input() showTimeZoneSelector: boolean = false;

    @Input() helpAvailable: boolean = false;

    @Input() headerPath: string;

    @Output() menuButtonClicked = new EventEmitter<void>()

    @Output() helpToggled = new EventEmitter<void>()



    constructor(
        private loggedInUserService: LoggedInUserService,
    )
    {
        this.timeZoneGroups = []

        let timeZones = moment.tz.names()
        for (let i = 0; i < timeZones.length; i++)
        {
            let pathParts = timeZones[i].split("/")

            let groupName: string

            if (pathParts.length > 1)
            {
                groupName = pathParts[0]
            }
            else
            {
                groupName = "Other"
            }

            // Create a new category for the first path part (usually denotes continent)
            let existingGroup = this.timeZoneGroups.find((group) =>
            {
                return group.name == groupName
            })

            if (existingGroup == null)
            {
                let newGroupIndex = this.timeZoneGroups.push({
                    name: groupName,
                    timeZones: []
                })

                existingGroup = this.timeZoneGroups[newGroupIndex - 1]
            }



            existingGroup.timeZones.push({
                label: timeZones[i].replace(/_/g, " "),
                value: timeZones[i]
            })
        }
    }

    ngOnInit()
    {

    }

    ngAfterViewInit()
    {
        this.loggedInUserService.overview().subscribe((settings) =>
            {

                this.userIsSystemAdmin = settings.isSystemAdmin

                if (settings.timeZone && this.timeZoneSelector)
                {
                    this.timeZoneSelector.nativeElement.value = settings.timeZone
                }
                else
                {
                    this.setToLocalTimeZone()
                }
            })
    }

    async timeZoneChanged(newZone: string)
    {
        this.timeZoneChanging.next(true)

        try
        {
            const userInfo = await this.loggedInUserService.setUserSettings({
                timeZone: newZone
            })
        }
        finally
        {
            this.timeZoneChanging.next(false)
        }
    }

    setToLocalTimeZone()
    {
        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

        this.timeZoneChanged(timeZone)
    }

}
